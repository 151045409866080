<template>
  <div class="g-section">
    <div class="g-header">
      <h3>OOD的12种常见面试题高频解析</h3>
      <p>每个案例以UML和代码模式讲解，清晰易懂，万能模版应用</p>
    </div>
    <div class="container">
      <div class="example" v-for="(item, index) in items" :key="index">
        <div class="top-label">
          <img
            class="example-label"
            src="@/assets/courseComponents/UMLspan.png"
            alt=""
          />
          <div class="label-content">
            {{ item.description }}
          </div>
        </div>
        <div class="detail-content">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UML",
  data() {
    return {
      items: [
        {
          text: "Parking Lot",
          description: "停车场",
        },
        {
          text: "Elevator",
          description: "电梯",
        },
        {
          text: "Amazon Locker",
          description: "亚马逊快递柜",
        },
        {
          text: "Vending Machine",
          description: "自动售货机",
        },
        {
          text: "Restaurant Reservation",
          description: "餐厅预订系统",
        },

        {
          text: "Hotel Reservation",
          description: "酒店预定系统",
        },
        {
          text: "Movie Ticket Booking",
          description: "电影票订票系统",
        },
        {
          text: "Tic Tac Toe",
          description: "井字游戏",
        },
        {
          text: "Chess",
          description: "国际象棋",
        },
        {
          text: "Black Jack",
          description: "21点纸牌游戏",
        },
        {
          text: "ATM Online",
          description: "ATM机",
        },
        {
          text: "Book Reader",
          description: "在线图书系统",
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.g-section {
  padding: 36px 0;
  width: 100%;
  min-height: 600px;
  position: relative;
  background: url(../../../assets/courseComponents/UMLbg.png) no-repeat;
  background-size: 100% 100%;
}
.umlbg {
  vertical-align: top;
  width: 100%;
}
.g-header {
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
}

.g-header h3 {
  font-size: 32px;
  margin-bottom: 12px;
}
.g-header p {
  margin-top: 8px;
  font-size: 16px;
  margin-bottom: 30px;
  color: #fff;
}

.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .example {
    width: 20%;
    margin: 16px 2%;
  }
}
.top-label {
  position: relative;
  z-index: 98;
  .example-label {
    vertical-align: top;
    height: 48px;
    width: 100%;
  }
  .label-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    line-height: 24px;
  }
}
.detail-content {
  height: 75px;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  transform: translateY(-20px);
  line-height: 90px;
  text-align: center;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: #000000;
}
</style>