<template>
  <div class="g-section">
    <div class="g-header">
      <h3>统一建模语言UML，OOD面试画图必备必学</h3>
      <p>UML介绍，UML类图，UML类关系讲解</p>
    </div>
    <div class="container">
      <div class="leftDes">
        <div class="title">UML &amp; OOD的完美结合应用</div>
        <img src="@/assets/courseComponents/UMLdetailBG.png" alt="" />
        <div class="description">
          <div v-for="(des, index) in descriptions" :key="index">{{ des }}</div>
        </div>
      </div>

      <div class="rightpic">
        <img src="@/assets/courseComponents/UMLdetailBG2.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UMLDetail",
  data() {
    return {
      descriptions: [
        "♦ 用UML去表达OOD的类和类之间的关系",
        "♦ 三步走万能解法的完美应用",
        "♦ 面试时清晰易懂的表示",
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.g-section {
  padding: 36px 0;
}
.g-header {
  text-align: center;
  margin-bottom: 30px;
}

.g-header h3 {
  font-size: 32px;
  margin-bottom: 12px;
}
.g-header p {
  margin-top: 8px;
  color: #676767;
  font-size: 16px;
  margin-bottom: 30px;
}

.container {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  img {
    width: 100%;
  }
}
.leftDes {
  display: inline-block;
  width: 430px;
  vertical-align: top;
  transform: translate(15px, 60%);
  img {
    vertical-align: top;
  }
  .title {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }
  .description {
    position: absolute;
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 48px;
    white-space: nowrap;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.rightpic {
  display: inline-block;
  width: 700px;
}
</style>