<template>
  <div class="g-section">
    <div class="g-header">
      <h3>七大设计原则全讲，而不是传统的SOLID五大原则</h3>
      <p>每一种设计原则不仅仅理论讲解，全部进行了代码实现</p>
    </div>
    <div class="container">
      <div class="rules" v-for="(rule, index) in rules" :key="index">
        <img src="@/assets/courseComponents/rulebg.png" alt="" />
        <div class="title">{{ rule.title }}</div>
        <div class="ruleDes">
          <div v-for="(des, dIndex) in rule.rules" :key="dIndex">
            {{ des }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ObjectOriented",
  data() {
    return {
      rules: [
        {
          title: "开闭原则",
          rules: ["Open Closed Principle"],
        },
        {
          title: "单一职责原则",
          rules: ["Single Responsibility Principle"],
        },
        {
          title: "里氏替换原则",
          rules: ["Liskov Substitution Principle"],
        },
        {
          title: "依赖倒置原则",
          rules: ["Dependence Inversion Principle"],
        },
        {
          title: "接口隔离原则",
          rules: ["Interface Segregation Principle"],
        },
        {
          title: "迪米特法则",
          rules: ["Law of Demeter"],
        },
        {
          title: "合成复用原则",
          rules: ["Composite Reuse Principle"],
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.g-section {
  padding: 36px 0;
}
.g-header {
  text-align: center;
  margin-bottom: 30px;
}

.g-header h3 {
  font-size: 32px;
  margin-bottom: 12px;
}
.g-header p {
  margin-top: 8px;
  color: #676767;
  font-size: 16px;
  margin-bottom: 30px;
}

.container {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .rules {
    position: relative;
    margin: 16px 3%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    img {
      width: 200px;
      height: 120px;
      vertical-align: top;
    }
    .title {
      position: absolute;
      left: 50%;
      top: 12px;
      transform: translateX(-50%);
      font-size: 18px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      white-space: nowrap;
    }
    .ruleDes {
      position: absolute;
      left: 50%;
      bottom: 20px;
      transform: translateX(-50%);
      white-space: nowrap;
      font-size: 14px;
      font-family: Arial;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 36px;
    }
  }
}
</style>