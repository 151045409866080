import { render, staticRenderFns } from "./UMLDetail.vue?vue&type=template&id=6af5a812&scoped=true"
import script from "./UMLDetail.vue?vue&type=script&lang=js"
export * from "./UMLDetail.vue?vue&type=script&lang=js"
import style0 from "./UMLDetail.vue?vue&type=style&index=0&id=6af5a812&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af5a812",
  null
  
)

export default component.exports