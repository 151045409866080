<template>
  <div class="g-section">
    <div class="g-header">
      <h3>OOD三步走万能解法，独家方法，自研套路</h3>
      <p>记叙文六要素:时间、地点、人物、事件的起因、经过、结果</p>
    </div>
    <div class="container">
      <img src="@/assets/courseComponents/ood3steps.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "CourseDetail",
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.g-section {
  padding: 36px 0;
}
.g-header {
  text-align: center;
  margin-bottom: 30px;
}

.g-header h3 {
  font-size: 32px;
  margin-bottom: 12px;
}
.g-header p {
  margin-top: 8px;
  color: #676767;
  font-size: 16px;
  margin-bottom: 30px;
}

.container {
  width: 800px;
  margin: 0 auto;
  img {
    width: 100%;
  }
}
</style>