<template>
  <div class="upload-ad" :style="{ backgroundImage: 'url(' + imgs + ')' }">
    <h3>{{title}}</h3>
    <h4>{{desc}}</h4>
	<router-link to="/course/11" class="btn" :class="btnClass">立即体验</router-link>
  </div>
</template>
<script>
export default {
  name: "freeSystem",
  props:{
	  title: {
		default:'',
		type: String,
	  },
	  desc: {
		default:'',
		type: String,
	  },
	  btnClass: {
		default:'btn1',
		type: String,
	  },
	  imgs: {
		default:require("@/assets/images/12-CsJob/banner.jpg"),
		type: String,
	  }
  },
  data() {
    return {};
  },
  methods: {
    freeVideo() {
      this.$router.push("/course/2");
    },
  },
};
</script>
<style scoped>
.btn {
  border-radius: 4px;
  padding: 0 !important;
}
.upload-ad{
	height: 220px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.upload-ad h4{
	margin-bottom: 32px;
}
.upload-ad .btn {
    display: inline-block;
    width: 180px;
    height: 32px;
    background-color: #FFFFFF;
    line-height: 32px;
    text-align: center;
	cursor: pointer;
}
.upload-ad .btn1 {
    color: #E02020;
}
.upload-ad .btn2 {
    color: #2b62fd;
}
</style>